import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  path?: string;
}

export default function SEO({ title, description, path = '' }: SEOProps) {
  const baseUrl = 'https://newton.obralink.com/olk-account-manager';
  const defaultTitle = 'Obralink User Dashboard';
  const defaultDescription =
    'Portal centralizado de gestión de proyectos de construcción Obralink';

  const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const fullUrl = `${baseUrl}${path}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name='description' content={description || defaultDescription} />
      <link rel='canonical' href={fullUrl} />

      <meta property='og:url' content={fullUrl} />
      <meta property='og:title' content={fullTitle} />
      <meta
        property='og:description'
        content={description || defaultDescription}
      />

      <meta name='twitter:url' content={fullUrl} />
      <meta name='twitter:title' content={fullTitle} />
      <meta
        name='twitter:description'
        content={description || defaultDescription}
      />
    </Helmet>
  );
}
